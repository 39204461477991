import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Button,
  Card,
  CardContent,
  LinearProgress,
  Grid,
  TextField,
} from "@mui/material";
import { Save } from "@mui/icons-material";
import BackLink from "../shared/BackLink";
import { useDb } from "../../contexts/DatabaseContext";
import FileUploader from "../shared/FileUploader";

const EditPost = () => {
  const { postId } = useParams();
  const navigate = useNavigate();
  const { getSingleDoc, updateDocument } = useDb();
  const [loading, setLoading] = useState(true);
  const [postData, setPostData] = useState(null);

  useEffect(() => {
    const fetchPostData = async () => {
      try {
        const docData = await getSingleDoc("posts", postId);
        console.log("Fetched post data:", docData); // Log fetched post data
        setPostData(docData);
      } catch (error) {
        console.error("Error fetching post data:", error);
      }
      setLoading(false);
    };

    fetchPostData();
  }, [getSingleDoc, postId]);

  const handleChange = (e, index) => {
    const { name, value } = e.target;
    const updatedContent = [...postData.content];
    updatedContent[index].value = value;
    setPostData((prevData) => ({
      ...prevData,
      content: updatedContent,
    }));
  };

  const handleFileUploadUrl = (url, index) => {
    const updatedContent = [...postData.content];
    updatedContent[index].value = url;
    setPostData((prevData) => ({
      ...prevData,
      content: updatedContent,
    }));
  };

  const handleSave = async () => {
    try {
      await updateDocument("posts", postId, postData);
      console.log("Post data updated successfully!");
      navigate("/post");
    } catch (error) {
      console.error("Error updating post data:", error);
    }
  };

  if (loading) {
    return <LinearProgress />;
  }

  if (!postData) {
    return null;
  }

  return (
    <div>
      <h1>Edit Post</h1>
      <BackLink />

      <Card sx={{ mt: 1 }}>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                name="title"
                label="Post Title"
                variant="outlined"
                fullWidth
                value={postData.title}
                onChange={(e) => handleChange(e, "title")}
              />
            </Grid>
            {postData.content.map((contentItem, index) => (
              <Grid item xs={12} key={index}>
                {contentItem.type === "text" && (
                  <TextField
                    id={`content-${index}`}
                    name={`content-${index}`}
                    label={contentItem.name}
                    variant="outlined"
                    fullWidth
                    value={contentItem.value}
                    onChange={(e) => handleChange(e, index)}
                  />
                )}
                {contentItem.type === "image" && (
                  <FileUploader
                    getUrl={(url) => handleFileUploadUrl(url, index)}
                  />
                )}
              </Grid>
            ))}
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                startIcon={<Save />}
                onClick={handleSave}
                fullWidth
              >
                Update Post
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
};

export default EditPost;
