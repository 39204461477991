import React from "react";
import { useCollection } from "react-firebase-hooks/firestore";
import { useDb } from "../../contexts/DatabaseContext";
import { Alert, IconButton, LinearProgress } from "@mui/material";
import RMFDataGrid from "../shared/data-grid/RMFDataGrid";
import { Search } from "@mui/icons-material";
import { Link } from "react-router-dom";

export default function Support() {
  const { supportCollection, queryCollection } = useDb();
  const query = queryCollection(supportCollection, "open", "==", true);
  const [snap, loading, error] = useCollection(query);

  if (loading) {
    return <LinearProgress />;
  }

  if (error) {
    console.error("Error fetching guides:", error);
    return (
      <Alert severity="error">
        Error fetching Support Tickets: {error.message}
      </Alert>
    );
  }

  const tickets =
    snap &&
    snap?.docs?.map((doc) => ({
      id: doc.id,
      ...doc.data(),
      ticket_time: doc.data().created_at.toDate(),
    }));

  const columns = [
    {
      field: "action",
      headerName: "Detail",
      width: 100,
      renderCell: (params) => {
        return (
          <Link to={`/support/${params.row.id}`}>
            <IconButton>
              <Search />
            </IconButton>
          </Link>
        );
      },
    },
    { field: "message", headerName: "Message", width: 200 },
    { field: "ticket_time", headerName: "Created At", width: 300 },
  ];

  return (
    <div>
      <h1>Support Tickets</h1>
      {tickets && <RMFDataGrid data={tickets} columns={columns} />}
    </div>
  );
}
