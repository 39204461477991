import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  LinearProgress,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import PropTypes from "prop-types";
import { storage } from "../../firebase";
import { useAuth } from "../../contexts/AuthContext";
import { CloudUpload } from "@mui/icons-material";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { fbApp } from "../../firebase";

function LinearProgressWithLabel(props) {
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2" color="textSecondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

LinearProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate and buffer variants.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
};

export default function FileUploader({ getUrl, label }) {
  const { currentUser } = useAuth();
  const storage = getStorage(fbApp);

  const [fileSelected, setFileSelected] = useState(false);
  const [docUrl, setDocUrl] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [error, setError] = useState("");
  const [file, setFile] = useState(null);

  const handleFileChange = (e) => {
    if (e.target.files[0]) {
      setFile(e.target.files[0]);
      setFileSelected(true);
    }
  };

  const handleUploadStart = async () => {
    setIsUploading(true);
    setProgress(0);
    console.log(file);
    const fileName = Math.random().toString(36).slice(-10);
    const storageRef = ref(
      storage,
      `users/${currentUser.uid}/uploads/${fileName}`
    );
    await uploadBytes(storageRef, file).then(async (snapshot) => {
      console.log("Uploaded a blob or file!");
      const url = await getDownloadURL(storageRef);
      getUrl(url);
      setProgress(100);
      setIsUploading(false);
      setFileSelected(false);
    });
  };

  return (
    <Card sx={{ mb: 1 }}>
      <CardContent>
        Upload Image: {label}
        <br />
        <input
          type="file"
          onChange={handleFileChange}
          accept="image/png, image/jpeg"
        />
        <LinearProgressWithLabel value={progress} />
        {error !== "" ? error.message : null}
      </CardContent>
      <CardActions className="iso-right">
        <Button
          disabled={!fileSelected || isUploading}
          onClick={handleUploadStart}
          startIcon={<CloudUpload />}
          variant="contained"
          color="primary"
        >
          Upload
        </Button>
      </CardActions>
    </Card>
  );
}
