import {
  Alert,
  Card,
  CardContent,
  CardHeader,
  LinearProgress,
  Typography,
} from "@mui/material";
import React from "react";
import { useDocumentData } from "react-firebase-hooks/firestore";

export default function UserCard({ userRef }) {
  const [doc, loading, error] = useDocumentData(userRef);

  if (error) {
    return <Alert severity="error">{error.message}</Alert>;
  }

  if (loading) {
    return <LinearProgress />;
  }

  const user = doc;

  const userName =
    user.name && user.surname
      ? `${user.name} ${user.surname}`
      : user.phone_number;

  return (
    <Card>
      <CardHeader subheader={userName} title="User Details" />
      <CardContent>
        <Typography>Phone Number: {user.phone_number}</Typography>
        {user.email && <Typography>Email: {user.phone_number}</Typography>}
        <Typography>Province: {user.province}</Typography>
      </CardContent>
    </Card>
  );
}
