import React from "react";
import { useCollection } from "react-firebase-hooks/firestore";
import { useDb } from "../../contexts/DatabaseContext";
import { LinearProgress } from "@mui/material";
import TemplateList from "./TemplateList";

const Templates = () => {
  const { templatesCollection } = useDb();

  const [snapshot, loading, error] = useCollection(templatesCollection);
  console.log(snapshot?.docs);

  if (loading) {
    return <LinearProgress />;
  }

  if (error) {
    console.error("Error fetching templates:", error);
    return <div>Error fetching Templates</div>;
  }

  const templates =
    snapshot &&
    snapshot?.docs?.map((template) => ({
      ...template.data(),
      id: template.id,
    }));

  console.log("Templates in Template:", templates);

  return <div>{templates && <TemplateList templates={templates} />}</div>;
};

export default Templates;
