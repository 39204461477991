import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Button,
  Card,
  CardContent,
  LinearProgress,
  Grid,
  TextField,
} from "@mui/material";
import { useDb } from "../../contexts/DatabaseContext";
import { Save } from "@mui/icons-material";
import BackLink from "../shared/BackLink";
import FileUploader from "../shared/FileUploader";
import Select from "react-select";
import { useCollectionOnce } from "react-firebase-hooks/firestore";
import { useNavigate } from "react-router-dom";

const EditNetworkOffering = () => {
  const { offeringId } = useParams();
  const { getSingleDoc, updateDocument } = useDb();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);

  const [networkOfferingData, setNetworkOfferingData] = useState({
    title: "",
    description: "",
    image: "",
  });

  useEffect(() => {
    const fetchNetworkData = async () => {
      try {
        const docData = await getSingleDoc("network_offerings", offeringId);
        setNetworkOfferingData(docData);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching product data:", error);
        setLoading(false);
      }
    };

    fetchNetworkData();
  }, [getSingleDoc, offeringId]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNetworkOfferingData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleUrl = (url) => {
    setNetworkOfferingData((prevData) => ({
      ...prevData,
      image: url,
    }));
  };

  const handleSave = async () => {
    console.log(offeringId);
    try {
      await updateDocument(
        "network_offerings",
        offeringId,
        networkOfferingData
      );
      console.log("Network Offering  data updated successfully!");
      navigate(-1);
    } catch (error) {
      console.error("Error updating Network Offering data:", error);
    }
  };

  if (loading) {
    return <LinearProgress />;
  }

  return (
    <div>
      <h1>Edit Network Offerings</h1>
      <BackLink />

      <Card sx={{ mt: 1 }}>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                name="title"
                label="Network Title"
                variant="outlined"
                fullWidth
                value={networkOfferingData.title}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="description"
                label="Description"
                variant="outlined"
                fullWidth
                value={networkOfferingData.description}
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={12}>
              <FileUploader getUrl={handleUrl} />
            </Grid>
            {networkOfferingData.image && (
              <Grid item xs={12}>
                <img
                  src={networkOfferingData.image}
                  alt="Uploaded Image"
                  style={{ maxWidth: "100%" }}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                startIcon={<Save />}
                onClick={handleSave}
                fullWidth
              >
                Update Network Offering
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
};

export default EditNetworkOffering;
