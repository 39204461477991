import React from "react";
import { Link } from "react-router-dom";
import { Button, Card, CardContent, Avatar } from "@mui/material";
import RMFDataGrid from "../shared/data-grid/RMFDataGrid";

const NetworkOfferingList = ({ networkOfferings }) => {
  console.log("NetworkOfferingList component rendered");
  const rows = networkOfferings || [];

  const columns = [
    {
      field: "image",
      headerName: " ",
      width: 60,
      renderCell: (params) => {
        return <Avatar src={params.row.image} />;
      },
    },
    { field: "title", headerName: "Title", width: 300 },
    {
      field: "id",
      headerName: "Action",
      width: 200,
      renderCell: (params) => (
        <Link to={`/edit-network-offering/${params.row.id}`}>
          <Button variant="outlined" color="primary">
            Edit
          </Button>
        </Link>
      ),
    },
  ];

  return (
    <div>
      <h1>Network Offerings</h1>
      <Link to="/add-network-offering">
        <Button variant="contained" color="primary">
          Add Network Offering
        </Button>
      </Link>
      <Card sx={{ mt: 1 }}>
        <CardContent>
          <RMFDataGrid data={rows} columns={columns} />
        </CardContent>
      </Card>
    </div>
  );
};

export default NetworkOfferingList;
