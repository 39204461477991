import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Button,
  Card,
  CardContent,
  LinearProgress,
  Grid,
  TextField,
} from "@mui/material";
import { useDb } from "../../contexts/DatabaseContext";
import { Save } from "@mui/icons-material";
import BackLink from "../shared/BackLink";
import FileUploader from "../shared/FileUploader";
import { useNavigate } from "react-router-dom";

const EditGuide = () => {
  const { guideId } = useParams();
  const { getSingleDoc, updateDocument } = useDb();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);

  const [guideData, setGuideData] = useState({
    title: "",
    description: "",
    image: "",
  });

  useEffect(() => {
    const fetchGuideData = async () => {
      try {
        const docData = await getSingleDoc("guides", guideId);
        setGuideData(docData);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching guides data:", error);
        setLoading(false);
      }
    };

    fetchGuideData();
  }, [getSingleDoc, guideId]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setGuideData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleUrl = (url) => {
    setGuideData((prevData) => ({
      ...prevData,
      image: url,
    }));
  };

  const handleSave = async () => {
    console.log(guideId);
    try {
      await updateDocument("guides", guideId, guideData);
      console.log("Guide  data updated successfully!");
      navigate(-1);
    } catch (error) {
      console.error("Error updating Guide data:", error);
    }
  };

  if (loading) {
    return <LinearProgress />;
  }

  return (
    <div>
      <h1>Edit Guides</h1>
      <BackLink />

      <Card sx={{ mt: 1 }}>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                name="title"
                label="Guide Title"
                variant="outlined"
                fullWidth
                value={guideData.title}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="description"
                label="Description"
                variant="outlined"
                fullWidth
                value={guideData.description}
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={12}>
              <FileUploader getUrl={handleUrl} />
            </Grid>
            {guideData.image && (
              <Grid item xs={12}>
                <img
                  src={guideData.image}
                  style={{ maxWidth: "100%" }}
                  alt="Guide "
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                startIcon={<Save />}
                onClick={handleSave}
                fullWidth
              >
                Update Guide
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
};

export default EditGuide;
