import {
  Button,
  CardActions,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import { textFieldProps } from "../../shared/constants";
import FileUploader from "../../shared/FileUploader";
import { ScheduleSend } from "@mui/icons-material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

export default function Template1Form({
  formState,
  setFormState,
  loading,
  handleSubmit,
  handleSchedule,
}) {
  const consumeUrl = (url, name) => {
    setFormState({
      ...formState,
      [name]: url,
    });
  };

  const handleDatePicked = (d) => {
    setFormState({
      ...formState,
      publish_date: d.toDate(),
    });
  };

  const [publishSchedule, setPublishSchedule] = useState("");
  return (
    <div>
      <Stack spacing={1}>
        <TextField
          {...textFieldProps}
          name="subtitle"
          onChange={(e) =>
            setFormState({
              ...formState,
              subtitle: e.target.value,
            })
          }
          label="Subtitle"
        />
        <TextField
          {...textFieldProps}
          name="bodyText"
          onChange={(e) =>
            setFormState({
              ...formState,
              bodyText: e.target.value,
            })
          }
          label="Body Text"
        />
        <FileUploader
          getUrl={(url) => consumeUrl(url, "headerImage")}
          name={"headerImage"}
          label={"Header Image"}
        />
        <FileUploader
          getUrl={(url) => consumeUrl(url, "secondaryImage")}
          name={"secondaryImage"}
          label={"Main Image"}
        />
        <FormControl>
          <FormLabel>Publishing Schedule</FormLabel>
          <RadioGroup onChange={(e) => setPublishSchedule(e.target.value)}>
            <FormControlLabel
              value="now"
              control={<Radio />}
              label="Publish Now"
            />
            <FormControlLabel
              value="schedule"
              control={<Radio />}
              label="Schedule Publish"
            />
          </RadioGroup>
        </FormControl>

        {publishSchedule === "now" && (
          <Button
            disabled={
              !formState.subtitle ||
              !formState.bodyText ||
              !formState.headerImage ||
              !formState.secondaryImage ||
              loading
            }
            size="large"
            variant="contained"
            color="primary"
            onClick={handleSubmit}
          >
            Publish Now
          </Button>
        )}
        {publishSchedule === "schedule" && (
          <>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker onChange={handleDatePicked} />
            </LocalizationProvider>
            <Button
              disabled={
                !formState.subtitle ||
                !formState.bodyText ||
                !formState.headerImage ||
                !formState.secondaryImage ||
                loading
              }
              size="large"
              variant="contained"
              color="primary"
              startIcon={<ScheduleSend />}
              onClick={handleSchedule}
            >
              Schedule Publish
            </Button>
          </>
        )}
      </Stack>
    </div>
  );
}
