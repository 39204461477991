import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Button,
  Card,
  CardContent,
  LinearProgress,
  Grid,
  TextField,
  Stack,
  MenuItem,
} from "@mui/material";
import { useDb } from "../../contexts/DatabaseContext";
import { Save } from "@mui/icons-material";
import BackLink from "../shared/BackLink";
import { useNavigate } from "react-router-dom";

const EditTemplate = () => {
  const { templateId } = useParams();
  const { getSingleDoc, updateDocument } = useDb();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [templateData, setTemplateData] = useState({});

  useEffect(() => {
    const fetchTemplateData = async () => {
      try {
        const docData = await getSingleDoc("templates", templateId);
        setTemplateData(docData);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching template data:", error);
        setLoading(false);
      }
    };

    fetchTemplateData();
  }, [getSingleDoc, templateId]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setTemplateData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleChangeInstructionName = (e, index) => {
    const { value } = e.target;
    setTemplateData((prevData) => ({
      ...prevData,
      instructions: prevData.instructions.map((instruction, i) =>
        i === index ? { ...instruction, name: value } : instruction
      ),
    }));
  };

  const handleChangeInstructionType = (e, index) => {
    const { value } = e.target;
    setTemplateData((prevData) => ({
      ...prevData,
      instructions: prevData.instructions.map((instruction, i) =>
        i === index ? { ...instruction, type: value } : instruction
      ),
    }));
  };

  const handleRemoveInstruction = (index) => {
    setTemplateData((prevData) => ({
      ...prevData,
      instructions: prevData.instructions.filter((_, i) => i !== index),
    }));
  };

  const handleAddInstruction = () => {
    setTemplateData((prevData) => ({
      ...prevData,
      instructions: [...prevData.instructions, { name: "", type: "text" }],
    }));
  };

  const handleSave = async () => {
    try {
      await updateDocument("templates", templateId, templateData);
      console.log("Template data updated successfully!");
      navigate(-1);
    } catch (error) {
      console.error("Error updating template data:", error);
    }
  };

  if (loading) {
    return <LinearProgress />;
  }

  return (
    <div>
      <h1>Edit Template</h1>
      <BackLink />
      <Card sx={{ mt: 1 }}>
        <CardContent>
          <Stack spacing={1}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  name="name"
                  label="Name"
                  variant="outlined"
                  fullWidth
                  value={templateData.name || ""}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <h3>Instructions:</h3>
                {templateData.instructions &&
                  templateData.instructions.map((instruction, index) => (
                    <div key={index}>
                      <TextField
                        label={`Instruction Name ${index + 1}`}
                        variant="outlined"
                        fullWidth
                        value={instruction.name || ""}
                        onChange={(e) => handleChangeInstructionName(e, index)}
                      />
                      <TextField
                        select
                        label={`Instruction Type ${index + 1}`}
                        variant="outlined"
                        fullWidth
                        value={instruction.type || ""}
                        onChange={(e) => handleChangeInstructionType(e, index)}
                      >
                        <MenuItem value="text">Text</MenuItem>
                        <MenuItem value="image">Image</MenuItem>
                      </TextField>
                      <Button onClick={() => handleRemoveInstruction(index)}>
                        Remove
                      </Button>
                    </div>
                  ))}
                <Button onClick={handleAddInstruction}>Add Instruction</Button>
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<Save />}
                  onClick={handleSave}
                  fullWidth
                >
                  Update Template
                </Button>
              </Grid>
            </Grid>
          </Stack>
        </CardContent>
      </Card>
    </div>
  );
};

export default EditTemplate;
