import React from "react";
import { useAuth } from "../../contexts/AuthContext";
import { Avatar, Card, CardActionArea, CardHeader, Grid } from "@mui/material";
import { Campaign, People, Route, SupportAgent } from "@mui/icons-material";
import { Link } from "react-router-dom";

export default function AdminHome() {
  const { currentUser } = useAuth();
  return (
    <div>
      <h2>Welcome to MyPAV, {currentUser.display_name}</h2>
      <Grid container spacing={3}>
        {currentUser.admin && (
          <Grid item xs={12} md={4}>
            <Card>
              <Link to="/guides">
                <CardActionArea>
                  <CardHeader
                    avatar={
                      <Avatar>
                        <Route />
                      </Avatar>
                    }
                    title="Guides"
                    subheader="Manage and publish guides"
                  />
                </CardActionArea>
              </Link>
            </Card>
          </Grid>
        )}
        {currentUser.admin && (
          <Grid item xs={12} md={4}>
            <Card>
              <Link to="/post">
                <CardActionArea>
                  <CardHeader
                    avatar={
                      <Avatar>
                        <Campaign />
                      </Avatar>
                    }
                    title="Promo Messages"
                    subheader="Send out promotional images and messages"
                  />
                </CardActionArea>
              </Link>
            </Card>
          </Grid>
        )}
        {currentUser.support_agent && (
          <Grid item xs={12} md={4}>
            <Card>
              <Link to="/support">
                <CardActionArea>
                  <CardHeader
                    avatar={
                      <Avatar>
                        <SupportAgent />
                      </Avatar>
                    }
                    title="Support"
                    subheader="View Support Tickets"
                  />
                </CardActionArea>
              </Link>
            </Card>
          </Grid>
        )}
      </Grid>
    </div>
  );
}
