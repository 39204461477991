import React from "react";
import { Link } from "react-router-dom";
import { Button, Card, CardContent, Avatar } from "@mui/material";
import RMFDataGrid from "../shared/data-grid/RMFDataGrid";
import { Delete, UploadFile } from "@mui/icons-material";
import { useDb } from "../../contexts/DatabaseContext";

const GuideList = ({ guides }) => {
  const { deleteStandardDoc } = useDb();
  console.log("GuidesList component rendered");
  const rows = guides || [];

  const handleDelete = async (guideId) => {
    const confirm = window.confirm(
      "Are you sure you want to delete this guide? This action cannot be undone."
    );
    if (confirm) {
      await deleteStandardDoc("guides", guideId);
    }
  };

  const columns = [
    {
      field: "image",
      headerName: " ",
      width: 60,
      renderCell: (params) => {
        return (
          <a href={params.row.image} target="_blank" rel="noopener noreferrer">
            <Avatar src={params.row.image} />
          </a>
        );
      },
    },
    { field: "title", headerName: "Title", width: 300 },
    { field: "network", headerName: "Network", width: 180 },
    {
      field: "id",
      headerName: "Action",
      width: 200,
      renderCell: (params) => (
        <Button
          size="small"
          startIcon={<Delete />}
          color="error"
          onClick={() => handleDelete(params.row.id)}
        >
          Delete
        </Button>
      ),
    },
  ];

  return (
    <div>
      <h1>Guides</h1>
      <Link to="/add-guide">
        <Button startIcon={<UploadFile />} variant="contained" color="primary">
          Add Guide
        </Button>
      </Link>
      <Card sx={{ mt: 1 }}>
        <CardContent>
          <RMFDataGrid data={rows} columns={columns} />
        </CardContent>
      </Card>
    </div>
  );
};

export default GuideList;
