import React from "react";
import { Link } from "react-router-dom";
import { Button, Card, CardContent, Avatar } from "@mui/material";
import RMFDataGrid from "../shared/data-grid/RMFDataGrid";

const MediaList = ({ media }) => {
  console.log(" MediaList component rendered");
  const rows = media || [];

  const columns = [
    {
      field: "image",
      headerName: " ",
      width: 60,
      renderCell: (params) => {
        return <Avatar src={params.row.image} />;
      },
    },
    { field: "title", headerName: "Title", width: 300 },
  ];

  return (
    <div>
      <h1>Media</h1>
      <Link to="/add-media">
        <Button variant="contained" color="primary">
          Add Media
        </Button>
      </Link>
      <Card sx={{ mt: 1 }}>
        <CardContent>
          <RMFDataGrid data={rows} columns={columns} />
        </CardContent>
      </Card>
    </div>
  );
};

export default MediaList;
