// NetworkOfferings.js

import React from "react";
import { useCollection } from "react-firebase-hooks/firestore";
import { useDb } from "../../contexts/DatabaseContext";
import { Button, Card, CardActions, LinearProgress } from "@mui/material";
import { Link } from "react-router-dom";

import GuideList from "./GuideList";

const Guides = () => {
  const { guidesCollection } = useDb();

  const [snapshot, loading, error] = useCollection(guidesCollection);
  console.log(snapshot?.docs);

  if (loading) {
    return <LinearProgress />;
  }

  if (error) {
    console.error("Error fetching guides:", error);
    return <div>Error fetching Guides</div>;
  }

  const guides =
    snapshot &&
    snapshot?.docs?.map((guide) => ({
      id: guide.id,
      ...guide.data(),
    }));

  console.log("Guides in Guides:", guides);

  return <div>{guides && <GuideList guides={guides} />}</div>;
};

export default Guides;
