import {
  createTheme,
  ThemeProvider,
  StyledEngineProvider,
} from "@mui/material";
import React from "react";
import { useAuth } from "../contexts/AuthContext";
import { DatabaseProvider } from "../contexts/DatabaseContext";
import PublicRoutes from "../routes/PublicRoutes";
import AdminPage from "./admin/AdminPage";
import CustomerPage from "./customer/CustomerPage";

// Set your theme colors and styles below. Details on Theming here: https://material-ui.com/customization/theming/#theming

const pavDarkBlue = "#18244e";
const pavLightBlue = "#14b0bd";
const theme = createTheme({
  palette: {
    primary: {
      main: pavDarkBlue,
      contrastText: "#fff",
    },
    mainMenuText: "#fff",
  },
  typography: {
    button: {
      textTransform: "none",
      fontWeight: "bold",
    },
  },
  components: {
    MuiAvatar: {
      styleOverrides: {
        colorDefault: {
          backgroundColor: pavLightBlue,
        },
      },
    },
  },
});

export default function Wrapper() {
  const { currentUser } = useAuth();
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <DatabaseProvider>
          {currentUser ? (
            currentUser.admin || currentUser.support_agent ? (
              <AdminPage />
            ) : (
              <CustomerPage />
            )
          ) : (
            <PublicRoutes />
          )}
        </DatabaseProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}
