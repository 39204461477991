import React, { useState } from "react";
import BackLink from "../shared/BackLink";
import {
  Card,
  TextField,
  CardContent,
  Stack,
  Alert,
  Grid,
  FormGroup,
  FormControlLabel,
  Checkbox,
  FormLabel,
} from "@mui/material";
import { useDb } from "../../contexts/DatabaseContext";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import {
  selectFieldProps,
  textFieldProps,
  networksNoCodes,
} from "../shared/constants";
import Template1Form from "./templates/Template1Form";
import Template2Form from "./templates/Template2Form";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

export default function RefactorAddPost() {
  const { addStandardDoc } = useDb();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();

  const [formState, setFormState] = useState({});

  const handleCheckbox = (e) => {
    setFormState({
      ...formState,
      [e.target.name]: e.target.checked,
    });
  };

  const handleExpiryDatePick = (d) => {
    setFormState({
      ...formState,
      expiry_date: d.toDate(),
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setLoading(true);
    try {
      const postId = await addStandardDoc("posts", formState);
      // Verify if postId is not undefined before navigating
      if (postId) {
        navigate(`/post`);
      } else {
        throw new Error("Failed to retrieve post ID.");
      }
    } catch (err) {
      window.alert(err.message);
    }
    setLoading(false);
  };

  const handleSchedule = async () => {
    setLoading(true);
    await addStandardDoc("scheduled_posts", { ...formState, sent: false });
    navigate(-1);
    setLoading(false);
  };

  const templateOptions = [{ value: "Template1", label: "Template1" }];

  if (error) {
    return <Alert severity="error">{error.message}</Alert>;
  }

  return (
    <div>
      <h1>Add Post</h1>
      <BackLink />
      <Card>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Stack spacing={1}>
                <TextField
                  {...textFieldProps}
                  label="Title"
                  name="title"
                  onChange={(e) =>
                    setFormState({
                      ...formState,
                      title: e.target.value,
                    })
                  }
                />
                <Select
                  options={templateOptions}
                  name="template"
                  onChange={(val) =>
                    setFormState({
                      ...formState,
                      template: val.value,
                    })
                  }
                  {...selectFieldProps}
                  placeholder="Select Template ..."
                  className="rmf-select"
                />
                <Select
                  options={[
                    ...networksNoCodes,
                    { value: "News", label: "News" },
                  ]}
                  placeholder="Select Network ..."
                  onChange={(val) =>
                    setFormState({
                      ...formState,
                      network: val.value,
                    })
                  }
                />
                <FormGroup>
                  <FormLabel>Publish To:</FormLabel>
                  <FormControlLabel
                    control={<Checkbox />}
                    label="My PAV"
                    name="target_mypav"
                    onChange={handleCheckbox}
                  />
                  <FormControlLabel
                    control={<Checkbox />}
                    label="PAV Connect"
                    name="target_connect"
                    onChange={handleCheckbox}
                  />
                </FormGroup>
                <FormLabel>Feature Post?</FormLabel>
                <FormControlLabel
                  control={<Checkbox />}
                  label="Feature this post on My Business"
                  name="featured"
                  onChange={handleCheckbox}
                />
                <FormGroup>
                  <FormControlLabel
                    control={<Checkbox />}
                    label="Remove at future date"
                    name="expire"
                    onChange={handleCheckbox}
                  />
                </FormGroup>
                {formState.expire && (
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker onChange={handleExpiryDatePick} />
                  </LocalizationProvider>
                )}
              </Stack>
            </Grid>
            <Grid item xs={12} md={6}>
              <Stack spacing={1}>
                {formState.template === "Template1" && (
                  <Template1Form
                    formState={formState}
                    setFormState={setFormState}
                    loading={loading}
                    handleSubmit={handleSubmit}
                    handleSchedule={handleSchedule}
                  />
                )}
                {formState.template === "Template2" && (
                  <Template2Form
                    formState={formState}
                    setFormState={setFormState}
                    loading={loading}
                    handleSubmit={handleSubmit}
                  />
                )}
              </Stack>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
}
