import { Button, Stack } from "@mui/material";
import React from "react";
import FileUploader from "../../shared/FileUploader";

export default function Template2Form({
  formState,
  setFormState,
  loading,
  handleSubmit,
}) {
  const consumeUrl = (url, name) => {
    setFormState({
      ...formState,
      [name]: url,
    });
  };
  console.log(loading);
  return (
    <div>
      <Stack spacing={1}>
        <FileUploader
          getUrl={(url) => consumeUrl(url, "headerImage")}
          name={"headerImage"}
          label={"Header Image"}
        />
        <FileUploader
          getUrl={(url) => consumeUrl(url, "secondaryImage")}
          name={"secondaryImage"}
          label={"Secondary Image"}
        />
        <FileUploader
          getUrl={(url) => consumeUrl(url, "tertiaryImage")}
          name={"tertiaryImage"}
          label={"Tertiary Image"}
        />
        )
        <Button
          disabled={
            !formState.headerImage ||
            !formState.secondaryImage ||
            !formState.tertiaryImage ||
            loading
          }
          size="large"
          variant="contained"
          color="primary"
          onClick={handleSubmit}
        >
          Submit
        </Button>
      </Stack>
    </div>
  );
}
