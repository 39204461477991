import { PersonAdd } from "@mui/icons-material";
import { Button, Stack } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import UserList from "./UserList";

export default function Users() {
  return (
    <div>
      <h1>Users</h1>
      <Stack spacing={2}>
        {/* <Link to="/import-users">
          <Button variant="contained" startIcon={<PersonAdd />}>
            Import Users
          </Button>
        </Link> */}
        <UserList />
      </Stack>
    </div>
  );
}
