import React, { useState } from "react";
import BackLink from "../shared/BackLink";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Container,
  TextField,
} from "@mui/material";
import { textFieldProps } from "../shared/constants";
import { Save } from "@mui/icons-material";
import FileUploader from "../shared/FileUploader";
import { useDb } from "../../contexts/DatabaseContext";
import { useNavigate } from "react-router-dom";

export default function AddGuide() {
  const { addStandardDoc } = useDb();
  const navigate = useNavigate();
  const [formState, setFormState] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();

  const handleChange = (e) => {
    setFormState({
      ...formState,
      [e.target.name]: e.target.value,
    });
  };

  const handleFileUploadUrl = (url) => {
    setFormState({
      ...formState,
      image: url,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setLoading(true);
    try {
      await addStandardDoc("media", formState);
      navigate(-1);
    } catch (err) {
      setError(err.message);
    }
    setLoading(false);
  };

  return (
    <div>
      <h1>Add Guide</h1>
      <BackLink />

      <Container>
        <Card>
          <form onSubmit={handleSubmit}>
            <CardContent>
              <TextField
                {...textFieldProps}
                name="title"
                onChange={handleChange}
                label="Title"
              />
              <FileUploader getUrl={handleFileUploadUrl} />
            </CardContent>
            <CardActions>
              <Button
                disabled={loading}
                type="submit"
                startIcon={<Save />}
                variant="contained"
              >
                Save
              </Button>
            </CardActions>
          </form>
        </Card>
      </Container>
    </div>
  );
}
