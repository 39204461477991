import React from "react";
import { useCollection } from "react-firebase-hooks/firestore";
import { useDb } from "../../contexts/DatabaseContext";
import { Button, LinearProgress, Stack } from "@mui/material";
import PostsList from "./PostsList";
import { Link } from "react-router-dom";
import { SendToMobile } from "@mui/icons-material";

export default function Posts() {
  const { postsCollection } = useDb();
  const [snapshot, loading, error] = useCollection(postsCollection);

  if (loading) {
    return <LinearProgress />;
  }

  const posts = snapshot?.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));

  return (
    <div>
      <h1>Promotional Messages</h1>
      <Stack spacing={2}>
        <Link to="/add-post">
          <Button
            startIcon={<SendToMobile />}
            variant="contained"
            color="primary"
          >
            New Promo Message
          </Button>
        </Link>
        {posts && <PostsList posts={posts} />}
      </Stack>
    </div>
  );
}
