import React, { useState } from "react";
import BackLink from "../shared/BackLink";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Container,
  TextField,
} from "@mui/material";
import { Save } from "@mui/icons-material";
import FileUploader from "../shared/FileUploader";
import { useDb } from "../../contexts/DatabaseContext";
import { useNavigate } from "react-router-dom";

export default function AddNetworkOffering() {
  const { addStandardDoc } = useDb();
  const navigate = useNavigate();
  const [formState, setFormState] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();

  const handleChange = (e) => {
    setFormState({
      ...formState,
      [e.target.name]: e.target.value,
    });
  };

  const handleFileUploadUrl = (url) => {
    setFormState({
      ...formState,
      image: url,
    });
  };

  // const handleDateChange = (date) => {
  //   setFormState({
  //     ...formState,
  //     expiryDate: date.format("YYYY-MM-DD"),
  //   });
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setLoading(true);
    try {
      await addStandardDoc("network_offerings", formState);
      navigate(-1);
    } catch (err) {
      setError(err.message);
    }
    setLoading(false);
  };

  return (
    <div>
      <h1>Add Network Offering</h1>
      <BackLink />

      <Container>
        <Card>
          <form onSubmit={handleSubmit}>
            <CardContent>
              <TextField
                name="title"
                onChange={handleChange}
                label="Title"
                fullWidth
                required
              />
              <TextField
                name="description"
                onChange={handleChange}
                label="Description"
                fullWidth
                required
              />
              {/* TODO: SETTING THE OFFER EXPIRY DATE  */}
              {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Expiry Date"
                  value={
                    formState.expiryDate ? dayjs(formState.expiryDate) : null
                  }
                  onChange={handleDateChange}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider> */}
              <FileUploader getUrl={handleFileUploadUrl} />
            </CardContent>
            <CardActions>
              <Button
                disabled={loading}
                type="submit"
                startIcon={<Save />}
                variant="contained"
              >
                Save
              </Button>
            </CardActions>
          </form>
        </Card>
      </Container>
    </div>
  );
}
