import React from "react";
import { useCollection } from "react-firebase-hooks/firestore";
import { useDb } from "../../contexts/DatabaseContext";
import { Alert, Button, LinearProgress } from "@mui/material";
import RMFDataGrid from "../shared/data-grid/RMFDataGrid";

export default function UserList() {
  const { usersCollection } = useDb();
  const [snapshot, loading, error] = useCollection(usersCollection);

  const users = snapshot?.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
    agentName: doc.data().display_name || `${doc.data().phone_number}`,
  }));

  const columns = [
    {
      field: "phone_number",
      headerName: "AgentNo",
      width: 200,
      renderCell: (params) => {
        return <Button>{params.row.phone_number}</Button>;
      },
    },
    { field: "agentName", headerName: "Agent Name", width: 200 },
    { field: "province", headerName: "Province", width: 200 },
  ];

  if (loading) {
    return <LinearProgress />;
  }

  if (error) {
    return <Alert severity="error">{error.message}</Alert>;
  }

  return (
    <div>
      <RMFDataGrid data={users} columns={columns} />
    </div>
  );
}
