// NetworkOfferings.js

import React from "react";
import { useCollection } from "react-firebase-hooks/firestore";
import { useDb } from "../../contexts/DatabaseContext";
import { Button, Card, CardActions, LinearProgress } from "@mui/material";
import { Link } from "react-router-dom";

import MediaList from "./MediaList";

const Media = () => {
  const { mediaCollection } = useDb();

  const [snapshot, loading, error] = useCollection(mediaCollection);
  console.log(snapshot?.docs);

  if (loading) {
    return <LinearProgress />;
  }

  if (error) {
    console.error("Error fetching media:", error);
    return <div>Error fetching Media</div>;
  }

  const media =
    snapshot &&
    snapshot?.docs?.map((media) => ({
      id: media.id,
      ...media.data(),
    }));

  console.log("Media in Media:", media);

  return <div>{media && <MediaList media={media} />}</div>;
};

export default Media;
