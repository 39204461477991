import React from "react";
import RMFDataGrid from "../shared/data-grid/RMFDataGrid";
import { Button, TableCell } from "@mui/material";
import { Delete } from "@mui/icons-material";
import { useDb } from "../../contexts/DatabaseContext";

const PostsList = ({ posts }) => {
  const { deleteStandardDoc } = useDb();

  const handleDelete = async (postId) => {
    const confirm = window.confirm(
      "Are you sure you want to delete this promo post? This action cannot be undone."
    );
    if (confirm) {
      await deleteStandardDoc("posts", postId);
    }
  };

  const rows =
    posts &&
    posts.map((post) => ({
      ...post,
    }));

  const columns = [
    {
      field: "title",
      headerName: "Title",
      width: 500,
      renderCell: (params) => {
        return (
          <Button
            href={params.row.headerImage}
            target="_blank"
            rel="noopener noreferrer"
          >
            {params.row.title}
          </Button>
        );
      },
    },
    {
      field: "id",
      headerName: "Action",
      width: 400,
      renderCell: (params) => {
        return (
          <TableCell>
            <Button
              size="small"
              startIcon={<Delete />}
              color="error"
              onClick={() => handleDelete(params.row.id)}
            >
              Delete
            </Button>
          </TableCell>
        );
      },
    },
  ];

  return <RMFDataGrid data={rows} columns={columns} />;
};

export default PostsList;
