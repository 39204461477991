import React, { useState } from "react";
import BackLink from "../shared/BackLink";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Container,
  TextField,
} from "@mui/material";
import { textFieldProps } from "../shared/constants";
import { Save } from "@mui/icons-material";
import { useDb } from "../../contexts/DatabaseContext";
import { useNavigate } from "react-router-dom";

export default function AddTemplate() {
  const { addStandardDoc } = useDb();
  const navigate = useNavigate();
  const [formState, setFormState] = useState({
    name: "",
    instructions: [],
    newInstructionName: "",
    newInstructionType: "",
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();

  const handleChange = (e) => {
    setFormState({
      ...formState,
      [e.target.name]: e.target.value,
    });
  };

  const handleAddInstruction = () => {
    const newInstruction = {
      name: formState.newInstructionName,
      type: formState.newInstructionType,
    };
    setFormState({
      ...formState,
      instructions: [...formState.instructions, newInstruction],
      newInstructionName: "",
      newInstructionType: "",
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setLoading(true);
    try {
      await addStandardDoc("templates", {
        name: formState.name,
        instructions: formState.instructions,
      });

      navigate(-1);
    } catch (err) {
      setError(err.message);
    }
    setLoading(false);
  };

  return (
    <div>
      <h1>Add Template</h1>
      <BackLink />

      <Container>
        <Card>
          <form onSubmit={handleSubmit}>
            <CardContent>
              <TextField
                {...textFieldProps}
                name="name"
                onChange={handleChange}
                label="Name"
                value={formState.name}
              />
              {/* Display existing instructions */}
              <div>
                <h3>Instructions:</h3>
                {formState.instructions.map((instruction, index) => (
                  <div key={index}>
                    {instruction.name} - {instruction.type}
                  </div>
                ))}
              </div>
              {/* Add new instruction inputs */}
              <TextField
                label="New Instruction Name"
                name="newInstructionName"
                value={formState.newInstructionName}
                onChange={handleChange}
              />
              <TextField
                label="New Instruction Type"
                name="newInstructionType"
                value={formState.newInstructionType}
                onChange={handleChange}
              />
              <Button onClick={handleAddInstruction}>Add Instruction</Button>
            </CardContent>
            <CardActions>
              <Button
                disabled={loading}
                type="submit"
                startIcon={<Save />}
                variant="contained"
              >
                Save
              </Button>
            </CardActions>
          </form>
        </Card>
      </Container>
    </div>
  );
}
