import { initializeApp } from "firebase/app";

const config = {
  // Paste your Firebase config here
  apiKey: "AIzaSyD96l56P8OZ1lEE92iBXJgGIzDI0jp7pP0",
  authDomain: "mypav-6fdc3.firebaseapp.com",
  projectId: "mypav-6fdc3",
  storageBucket: "mypav-6fdc3.appspot.com",
  messagingSenderId: "912941583540",
  appId: "1:912941583540:web:ea4a6c20f0393cb8331be1",
  measurementId: "G-QD8XBSZ074",
};

const app = initializeApp(config);
export const fbApp = app;
