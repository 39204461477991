import React, { useContext } from "react";
import { fbApp } from "../firebase";
import {
  getFirestore,
  collection,
  addDoc,
  deleteDoc,
  doc,
  getDoc,
  query,
  where,
  updateDoc,
} from "firebase/firestore";
import { useAuth } from "./AuthContext";

const DatabaseContext = React.createContext();

export function useDb() {
  return useContext(DatabaseContext);
}

export function DatabaseProvider({ children }) {
  const { currentUser } = useAuth();
  const db = getFirestore(fbApp);

  // Collection References
  const usersCollection = collection(db, "users");
  const networkOfferingsCollection = collection(db, "network_offerings");
  const guidesCollection = collection(db, "guides");
  const mediaCollection = collection(db, "media");
  const templatesCollection = collection(db, "templates");
  const postsCollection = collection(db, "posts");
  const supportCollection = collection(db, "support_tickets");
  const notificationsCollection = collection(db, "notifications");

  async function addStandardDoc(collectionName, data) {
    const docRef = await addDoc(collection(db, collectionName), {
      ...data,
      created_by: currentUser.uid,
      created_at: new Date(),
      updated_at: new Date(),
    });
    return docRef.id;
  }

  async function getSingleDoc(collectionName, documentId) {
    const ref = doc(db, collectionName, documentId);
    const docSnap = await getDoc(ref);
    return {
      ...docSnap.data(),
      id: docSnap.id,
    };
  }

  function deleteStandardDoc(collectionName, docId) {
    const ref = doc(db, collectionName, docId);
    return deleteDoc(ref);
  }

  function updateDocument(collectionName, documentId, data) {
    const docRef = doc(db, collectionName, documentId);
    return updateDoc(docRef, data);
  }

  function queryCollection(collection, fieldName, operator, fieldValue) {
    return query(collection, where(fieldName, operator, fieldValue));
  }

  function getDocRef(collectionName, documentId) {
    const docRef = doc(db, collectionName, documentId);
    return docRef;
  }

  const value = {
    usersCollection,
    networkOfferingsCollection,
    getSingleDoc,
    updateDocument,
    addStandardDoc,
    queryCollection,
    guidesCollection,
    templatesCollection,
    mediaCollection,
    postsCollection,
    deleteStandardDoc,
    supportCollection,
    getDocRef,
    notificationsCollection,
  };

  return (
    <DatabaseContext.Provider value={value}>
      {children}
    </DatabaseContext.Provider>
  );
}
