import React, { useState } from "react";
import BackLink from "../shared/BackLink";
import { Button, TextField } from "@mui/material";
import { textFieldProps } from "../shared/constants";
import { PersonAdd } from "@mui/icons-material";
import { getFunctions, httpsCallable } from "firebase/functions";

export default function AddUser() {
  const [newUser, setNewUser] = useState({});
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    setNewUser({
      ...newUser,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async () => {
    setLoading(true);
    const functions = getFunctions();
    const manuallyCreateAgent = httpsCallable(functions, "manuallyCreateAgent");

    const result = await manuallyCreateAgent({
      msisdn: newUser.msisdn,
      firstName: newUser.name,
      surname: newUser.surname,
    });

    console.log(result);
    setLoading(false);
  };

  return (
    <div>
      <h1>Add User</h1>
      <BackLink />
      <TextField
        {...textFieldProps}
        label="MSISDN"
        name="msisdn"
        onChange={handleChange}
        required
      />
      <TextField
        {...textFieldProps}
        label="First Name"
        name="name"
        onChange={handleChange}
        required
      />
      <TextField
        {...textFieldProps}
        label="Last Name"
        name="surname"
        onChange={handleChange}
        required
      />
      <Button
        variant="contained"
        startIcon={<PersonAdd />}
        onClick={handleSubmit}
        disabled={loading}
      >
        Create User
      </Button>
    </div>
  );
}
