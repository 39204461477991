import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useDb } from "../../contexts/DatabaseContext";
import { useDocumentData } from "react-firebase-hooks/firestore";
import {
  Alert,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  LinearProgress,
  Snackbar,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import BackLink from "../shared/BackLink";
import UserCard from "../users/UserCard";
import { textFieldProps } from "../shared/constants";

export default function SupportDetail() {
  const { ticketId } = useParams();
  const navigate = useNavigate();
  const { getDocRef, updateDocument, addStandardDoc } = useDb();
  const [feedback, setFeedback] = useState();
  const [sending, setSending] = useState(false);
  const [snackOpen, setSnackOpen] = useState(false);

  const ticketRef = getDocRef("support_tickets", ticketId);

  const [doc, loading, error] = useDocumentData(ticketRef);

  if (loading) {
    return <LinearProgress />;
  }

  if (error) {
    console.error("Error fetching guides:", error);
    return (
      <Alert severity="error">
        Error fetching Support Ticket: {error.message}
      </Alert>
    );
  }

  const ticket = doc;

  console.log(ticket.user_ref.id);

  const handleFeedbackSend = async () => {
    setSending(true);
    try {
      await updateDocument("support_tickets", ticketId, {
        feedback,
      });
      await addStandardDoc("notifications", {
        title: "Response to Ticket",
        subtitle: feedback,
        uid: ticket.user_ref.id,
        created: new Date(),
        read: false,
        body: feedback,
      });
      setSnackOpen(true);
    } catch (err) {
      window.alert(err.message);
    }
    setSending(false);
  };

  const handleClose = async () => {
    setSending(true);
    try {
      await updateDocument("support_tickets", ticketId, {
        open: false,
      });
      await addStandardDoc("notifications", {
        title: "Ticket Closed",
        subtitle: "Your support ticket has been closed.",
        body: "Your support ticket has been closed.",
        uid: ticket.user_ref.id,
        created: new Date(),
        read: false,
      });
      navigate(-1);
      setSnackOpen(true);
    } catch (err) {
      window.alert(err.message);
    }
    setSending(false);
  };

  return (
    <div>
      <h1>Support Ticket</h1>
      <BackLink />

      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Card>
            <CardHeader title="Ticket Details" />
            <CardContent>
              <Stack spacing={2}>
                <div>
                  <Typography fontWeight="bold">Message:</Typography>
                  <Typography>{ticket.message}</Typography>
                </div>
                <div>
                  <Typography fontWeight="bold">
                    Alternate Contact Number:
                  </Typography>
                  <Typography>{ticket.al_contact}</Typography>
                </div>
                <div>
                  <Typography fontWeight="bold">Sent:</Typography>
                  <Typography>
                    {ticket.created_at.toDate().toString()}
                  </Typography>
                </div>
                <TextField
                  {...textFieldProps}
                  label="Feedback Message..."
                  defaultValue={ticket.feedback}
                  value={feedback}
                  onChange={(e) => setFeedback(e.target.value)}
                />
              </Stack>
            </CardContent>
            <CardActions>
              <Button
                disabled={sending || !feedback}
                variant="outlined"
                onClick={handleFeedbackSend}
              >
                Send Feedback Message
              </Button>
              <Button
                disabled={sending}
                variant="contained"
                onClick={handleClose}
              >
                Mark Ticket Closed
              </Button>
            </CardActions>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <UserCard userRef={ticket.user_ref} />
        </Grid>
      </Grid>

      <Snackbar
        autoHideDuration={5000}
        open={snackOpen}
        onClose={() => setSnackOpen(false)}
      >
        <Alert>Ticket Updated!</Alert>
      </Snackbar>
    </div>
  );
}
