// NetworkOfferings.js

import React from "react";
import { useCollection } from "react-firebase-hooks/firestore";
import { useDb } from "../../contexts/DatabaseContext";
import { Button, Card, CardActions, LinearProgress } from "@mui/material";
import { Link } from "react-router-dom";

import NetworkOfferingList from "./NetworkOfferingList";

const NetworkOfferings = () => {
  const { networkOfferingsCollection } = useDb();

  const [snapshot, loading, error] = useCollection(networkOfferingsCollection);
  console.log(snapshot?.docs);

  if (loading) {
    return <LinearProgress />;
  }

  if (error) {
    console.error("Error fetching network offerings:", error);
    return <div>Error fetching network offerings</div>;
  }

  const networkOfferings =
    snapshot &&
    snapshot?.docs?.map((networkOffering) => ({
      id: networkOffering.id,
      ...networkOffering.data(),
    }));

  console.log("Network offerings in NetworkOfferings:", networkOfferings);

  return (
    <div>
      {networkOfferings && (
        <NetworkOfferingList networkOfferings={networkOfferings} />
      )}
    </div>
  );
};

export default NetworkOfferings;
